import request from "@/util/request";

export function getpage(param) {
    return request({
        url: "/api/admin/pay/page",
        headers: {
            isToken: true,
        },
        method: "post",
        data: param,
    });
}
export function getAllPayNum() {
    return request({
        url: "/api/admin/pay/getAllPayNum",
        headers: {
            isToken: true,
        },
        method: "post",
    });
}