<template>
    <div class="main_main_body">

        <div class="main_main_body_top">
            <div class="main_main_body_top_left">
                <el-icon>
                    <Monitor />
                </el-icon>
                <div class="title_text">管理员中心</div>
            </div>
            <div class="main_main_body_top_right"></div>
        </div>
        <div class="main_main_body_main">
            
            <div v-if="analyse_list_loading" class="loading_center">
                <Loading></Loading>
            </div>
           
        </div>
        <div class="bottm_title_info">Powered&nbsp;by&nbsp;Chen</div>
    </div>
</template>
<script>
import { Monitor } from "@element-plus/icons-vue";
import Loading from "@/components/loading/loading.vue"
export default {
    data(){
        return {
            analyse_list_loading:true
        }
    },
    components: {
        Monitor,
        Loading
    },
};
</script>
<style lang="less" scoped></style>