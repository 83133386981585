
import request from "@/util/request";
export function getUserPage(param) {
    return request({
        url: "/api/admin/user/page",
        headers: {
            isToken: true,
        },
        method: "post",
        data: param,
    });
}

export function getUserRegirstInfo(param) {
    return request({
        url: "/api/admin/user/userRegirstInfo",
        headers: {
            isToken: true,
        },
        method: "post",
    });
}

export function login(param){
    return request({
        url: "/api/admin/login",
        headers: {
            isToken: true,
        },
        method: "post",
        data:param,
    });
}