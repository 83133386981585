<template>
    <div class="main_main_body">
        <div class="main_main_body_top">
            <div class="main_main_body_top_left">
                <el-icon>
                    <UserFilled />
                </el-icon>
                <div class="title_text">用户中心</div>
            </div>
            <div class="main_main_body_top_right"></div>
        </div>
        <div class="main_main_body_main">
            <div class="main_main_body_main_card_top">
                <div class="main_main_body_main_card_top_left">
                    <el-input class="main_main_body_main_card_top_left_search_input" v-model="page_info.username"
                        placeholder="请输入用户昵称" clearable />
                    <el-input class="main_main_body_main_card_top_left_search_input" v-model="page_info.phone"
                        placeholder="请输入用户手机号" clearable />
                </div>
                <div class="main_main_body_main_card_top_right">
                    <el-button class="item" type="primary" @click="getUser">查询</el-button>
                </div>
            </div>
            <div v-if="user_list_loading" class="loading_center">
                <Loading></Loading>
            </div>
            <div v-if="!user_list_loading" class="main_main_body_main_card_body">
                <div class="user_card_info_item" v-for="(item, index) in user_list">
                    <div class="user_card_info_item_left">
                        <div class="user_card_info_item_left_info">
                            <div class="user_card_info_item_left_info_top">{{ item.userName }}</div>
                            <div class="user_card_info_item_left_info_bottom">{{ item.phone }}</div>
                        </div>
                    </div>
                    <div class="user_card_info_item_right">
                        <div class="user_card_info_item_right_balance">{{ item.balance.replace(/(\d)(?=(?:\d{3})+$)/g,
                            '$1,') }}点</div>
                        <div class="user_card_info_item_right_tag_1">
                            <el-tag v-if="item.revision === 0" type="primary">正常</el-tag>
                            <el-tag v-else type="danger">停用</el-tag>
                        </div>
                        <div class="user_card_info_item_right_create_time_1">{{ item.createdTime }}</div>
                    </div>
                </div>
            </div>
            <div v-if="!user_list_loading" class="main_main_body_main_card_bottom">
                <div>共{{ user_list_length }}项数据</div>
                <div><el-pagination v-model:current-page="page_info.page" v-model:page-size="page_info.pageNum"
                        :page-sizes="[20, 50, 100, 400]" small :disabled="page_disabled"
                        layout="total, sizes, prev, pager, next, jumper" :total="user_list_total"
                        :page-count="page_count" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" /></div>
            </div>
        </div>
        <div class="bottm_title_info">Powered&nbsp;by&nbsp;Chen</div>
    </div>
</template>
<script>
import { UserFilled } from "@element-plus/icons-vue";
import { getUserPage } from "@/api/user"
import Loading from "@/components/loading/loading.vue"
export default {
    data() {
        return {
            page_info: {
                username: '',
                phone: '',
                pageNum: 20,
                page: 1,
            },
            page_disabled: false,
            page_count: 0,
            user_list_total: 0,
            user_list_loading: false,
            user_list: [],
        }
    },
    components: {
        UserFilled,
        Loading
    },
    computed: {
        user_list_length() {
            return this.user_list.length
        }
    },
    mounted() {
        this.getUser()
    },
    methods: {
        getUser() {
            this.page_disabled = true
            this.user_list_loading = true
            getUserPage(this.page_info).then(res => {
                console.log(res);
                if (res?.code === 200) {
                    this.user_list = res.data.records
                    this.user_list_total = res.data.total
                    this.page_count = res.data.pages
                }
                this.user_list_loading = false
                this.page_disabled = false
            })
        },
        handleCurrentChange(param) {
            this.page_info.page = param
            this.getUser()
        },
        handleSizeChange(param) {
            this.page_info.page = 1
            this.page_info.pageNum = param
            this.getUser()
        }
    }
};
</script>
<style lang="less" scoped></style>