<template>
    <div class="main_main_body">
        <div class="main_main_body_top">
            <div class="main_main_body_top_left">
                <el-icon>
                    <Calendar />
                </el-icon>
                <div class="title_text">订单中心</div>
            </div>
            <div class="main_main_body_top_right"></div>
        </div>
        <div class="main_main_body_main">
            <div class="main_main_body_main_card_top">
                <div class="main_main_body_main_card_top_left">
                    <el-input class="main_main_body_main_card_top_left_search_input" v-model="page.phone" placeholder="订单创建手机号" clearable/>
                    <el-input class="main_main_body_main_card_top_left_search_input" v-model="page.totalnumb" placeholder="订单金额" clearable/>
                    <el-select class="main_main_body_main_card_top_left_search_chose" v-model="page.type"
                        placeholder="支付方式" clearable>
                        <el-option :key="0" label="支付宝" :value="1" />
                        <el-option :key="1" label="微信" :value="2" />
                    </el-select>
                    <el-select class="main_main_body_main_card_top_left_search_chose" v-model="page.state"
                        placeholder="支付状态" clearable>
                        <el-option :key="0" label="未支付" :value="0" />
                        <el-option :key="1" label="已支付" :value="2" />
                    </el-select>
                </div>
                <div class="main_main_body_main_card_top_right">
                    <el-button class="item" type="primary" @click="getOrderPage">查询</el-button>
                </div>
            </div>
            <div v-if="order_list_loading" class="loading_center">
                <Loading></Loading>
            </div>
            <div v-if="!order_list_loading" class="main_main_body_main_card_body">
                <div class="user_card_info_item" v-for="(item, index) in order_list">
                    <div class="user_card_info_item_left">
                        <div class="user_card_info_item_left_info">
                            <div class="user_card_info_item_left_info_top">{{ item?.message?item?.message:"余额充值" }}</div>
                            <div class="user_card_info_item_left_info_bottom">{{ item.orderId }}</div>
                        </div>
                        <div class="user_card_info_item_left_phone">{{ item.createdBy }}</div>
                        <div class="user_card_info_item_left_price">{{ item.goodsType }}点</div>
                        <div class="user_card_info_item_left_price">{{ item.totalAmount }}元</div>
                    </div>
                    <div class="user_card_info_item_right">
                        <div class="user_card_info_item_right_tag_1">
                            <el-tag v-if="item.state === '2'" type="primary">已支付</el-tag>
                            <el-tag v-else type="danger">未支付</el-tag>
                        </div>
                        <div class="user_card_info_item_right_create_time_1">{{ item.createdTime }}</div>
                    </div>
                </div>
            </div>
            <div v-if="!order_list_loading" class="main_main_body_main_card_bottom">
                <div>共1项数据</div>
                <div><el-pagination v-model:current-page="page.page" v-model:page-size="page.pageNum"
                        :page-sizes="[20, 50, 100, 400]" small :disabled="page_disabled" :background="background"
                        layout="total, sizes, prev, pager, next, jumper" :total="order_list_total"
                        :page-count="page_count" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" /></div>
            </div>
        </div>
        <div class="bottm_title_info">Powered&nbsp;by&nbsp;Chen</div>
    </div>
</template>
<script>
import { Calendar } from "@element-plus/icons-vue";
import { getpage } from "@/api/pay"
import Loading from "@/components/loading/loading.vue"
export default {
    data() {
        return {
            page: {
                totalnumb:"",
                phone:"",
                state:"",
                type:"",
                page: 1,
                pageNum: 20,
            },
            page_disabled: false,
            page_count: 0,
            order_list_total: 0,
            order_list_loading: false,
            order_list: [{ id: "1", phone: "177701545524", balance: "123", username: "余额充值", tranform: "30", create_time: "2024-05-12 12:05:50", revision: 0 }],
        }
    },
    components: {
        Calendar,
        Loading
    },
    mounted() {
        this.getOrderPage()
    },
    methods: {
        getOrderPage() {
            this.order_list_loading = true
            this.page_disabled = true
            getpage(this.page).then(res => {
                if (res?.code === 200) {
                    this.order_list = res?.data?.records || []
                    this.order_list_total = res.data.total
                    this.page_count = res.data.pages
                }
                this.order_list_loading = false
                this.page_disabled = false
            })
        },
        handleCurrentChange(param) {
            this.page.page = param
            this.getOrderPage()
        },
        handleSizeChange(param) {
            this.page.page = 1
            this.page.pageNum = param
            this.getOrderPage()
        }
    }
};
</script>
<style lang="less" scoped></style>