import { createRouter, createWebHashHistory } from "vue-router";
import indexMenu from "@/views/index/indexMenu.vue";
import indexStatistics from "@/views/statistics/indexStatistics.vue";
import userList from "@/views/user/list.vue";
import indexOrder from "@/views/order/indexOrder.vue";
import indexTask from "@/views/task/indexTask.vue";
import indexAnalyse from "@/views/analyse/indexAnalyse.vue";
import indexAffairs from "@/views/affairs/indexAffairs.vue";
import loginIndex from "@/views/login/loginIndex.vue";
import indexDy from "@/views/dy/indexDy.vue";
const routes = [
  {
    path: "/",
    name: "index",
    component: indexMenu,
    children: [
      {
        path: "",
        component: indexStatistics,
      },
      {
        path: "indexStatistics",
        component: indexStatistics,
      },
      {
        path: "indexOrder",
        component: indexOrder,
      },
      {
        path: "indexTask",
        component: indexTask,
      },
      {
        path: "userList",
        component: userList,
      },
      {
        path: "indexAnalyse",
        component: indexAnalyse,
      },
      {
        path: "indexAffairs",
        component: indexAffairs,
      },
      {
        path: "indexDy",
        component: indexDy,
      }
    ],
  },
  {
    path: "/login",
    name: "login",
    component: loginIndex
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
