<template>
  <div class="all_info">
      <router-view></router-view>
  </div>
</template>

<script>
export default {
};
</script>
<style lang="less" scoped>
.all_info {
  min-width: 1005px;
  background: #eff4f9;
}
</style>
