import { getToken, setToken } from "@/util/auth";
import { login } from "@/api/user";

const user = {
  state: {
    token: getToken(),
    name: "",
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
  },
  actions: {
    Login({ commit }, param) {
      return new Promise((resolve, reject) => {
        login(param)
          .then((res) => {
            commit("SET_TOKEN", res.data?.token);
            setToken(res.data?.token);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default user;
