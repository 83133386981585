import { createStore } from "vuex";
import app from "./modules/app";
import user from "./modules/user";

export default createStore({
  state: {},
  getters: {
    token: (state) => state.user.token,
    name: (state) => state.user.name,
  },
  mutations: {},
  actions: {},
  modules: {
    app,
    user,
  },
});
