<template>
    <layoutIndex :show_loading="affairs_list_loading">
        <template #top_left>
            <el-icon>
                <Stamp />
            </el-icon>
            <div class="title_text">抖音小程序</div>
        </template>

        <template #top_right>
        </template>
        <template #data_top>
            <div class="main_main_body_main_card_top_left">
                <el-input class="main_main_body_main_card_top_left_search_input" v-model="page_info.id" placeholder="请输入信息" />
            </div>
            <div class="main_main_body_main_card_top_right">
                <el-button class="item" type="primary" @click="getInfoData">查询</el-button>
            </div>
        </template>
        <template #data_info>
            <div class="main_main_body_main_card_body">
                <div class="user_card_info_item" v-for="(item, index) in affairs_list">
                    <div class="user_card_info_item_left_1">
                        <div class="user_card_info_item_left_info">
                            <div class="user_card_info_item_left_info_top">{{ item.openid }}</div>
                        </div>
                    </div>
                    <div class="user_card_info_item_right_1">
                        <div class="user_card_info_item_right_balance two-line-clamp">{{item.textInfo}}</div>
                        <div class="user_card_info_item_right_create_time_2">
                            <div class="user_card_info_item_right_create_time_2_top">创建时间：{{ item.createTime }}
                            </div>
                            <div v-if="item.updateTime" class="user_card_info_item_right_create_time_2_top">
                                更新时间：{{ item.updateTime }}</div>
                        </div>
                        <el-button type="info" size="small" class="user_card_info_item_right_button"
                            @click="open_mask(item)">更改</el-button>
                    </div>
                </div>
            </div>
        </template>
        <template #page_info>
            <pageinfoIndex :list_num="affairs_list.length" :page_info="page_info" :page_disabled="affairs_disabled"
                :page_count="page_count" :list_total="affairs_list_total" @handleSizeChange="sizeChange"
                @handleCurrentChange="currentChange"></pageinfoIndex>
        </template>
    </layoutIndex>
    <div v-if="show_mask" class="msk" @click="close_msk">
        <div class="body_form wid_800" @click="no_pop_up">
            <div class="form_top">
                <div class="title">账号信息变更</div>
            </div>
            <div class="body_form_item_body">
                <div class="item">
                    <div class="text">openid:</div>
                    <div class="input_text">{{ submit_form.openid }}</div>
                </div>
                <div class="item">
                    <div class="text">信息:</div>
                    <el-input class="input" type="textarea" :rows="6" v-model="submit_form.textInfo" placeholder="输入账号json数据" />
                </div>
            </div>
            <div class="item_button">
                <el-button class="item" @click="submit_form_info">提交</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { Stamp } from "@element-plus/icons-vue"
import layoutIndex from "@/components/layout/layoutIndex.vue"
import pageinfoIndex from "@/components/layout/pageinfoIndex.vue"
import { getPage, updateOrSave } from "@/api/price"
import { getInfoByPage,update } from "@/api/dy"
import { ElMessage } from "element-plus"
export default {
    data() {
        return {
            submit_form: {

            },
            page_info: {
                id:"",
                pageNum: 20,
                page: 1,
            },
            affairs_disabled: false,
            page_count: 0,
            affairs_list_total: 0,
            affairs_list_loading: false,
            affairs_list: [],
            show_mask: false
        }
    },
    components: {
        Stamp,
        layoutIndex,
        pageinfoIndex
    },
    created() {
        this.getInfoData()
    },
    methods: {
        getInfoData() {
            this.affairs_disabled = true
            this.affairs_list_loading = true
            getInfoByPage(this.page_info).then(res => {
                console.log("getInfoByPage", res);
                if (res?.code === 200) {
                    this.affairs_list = res.data.records
                    this.affairs_list_total = res.data.total
                    this.page_count = res.data.pages
                }
                this.affairs_list_loading = false
                this.affairs_disabled = false
            }).catch(err => {
                this.affairs_list_loading = false
                this.affairs_disabled = false
            })

        },
        currentChange(param) {
            this.getInfoData()
            this.page_info.page = param
        },
        sizeChange(param) {
            this.getInfoData()
            this.page_info.page = 1
            this.page_info.pageNum = param
        },
        open_mask(param) {
            let that = this
            that.show_mask = true
            if (param?.add) {
                that.submit_form = {

                }
            } else {
                that.submit_form = param
            }
        },
        no_pop_up(e) {
            e.stopPropagation()
        },
        close_msk() {
            let that = this
            that.show_mask = false
        },
        submit_form_info() {
            let that = this
            update(that.submit_form).then(res => {
                that.show_mask = false
            })
        }
    }
};
</script>
<style lang="less" scoped></style>