<template>
  <div class="login_info">
    <div class="item">
      <div class="item_title">登录</div>
      <div class="item_form">
        <div class="form">
          <div class="form_input">
            <input
              class="input_phone"
              v-model="loginform.username"
              placeholder="账号"
            />
          </div>
          <div class="form_input">
            <input
              class="input_code"
              type="password"
              v-model="loginform.password"
              placeholder="密码"
            />
          </div>
          <div class="form_button">
            <button @click="user_login">登录&nbsp;/&nbsp;注册</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElMessage } from "element-plus";
import { ElNotification } from "element-plus";
export default {
  data() {
    return {
      sendMessage_text: "获取验证码",
      max_time: 60,
      text_show: "获取验证码",
      sendbutton_show: true,
      loginform: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    user_login() {
      // let reg = /^\d$/
      // if (this.loginform.username.length <= 8) {
      //   ElMessage({
      //     message: '手机号长度至少8位',
      //     offset: 100,
      //     type: "error",
      //     plain: true,
      //   });
      //   return
      // }
      // if (!reg.test(this.loginform.username)) {
      //   ElMessage({
      //     message: '手机号格式不正确',
      //     offset: 100,
      //     type: "error",
      //     plain: true,
      //   });
      //   return
      // }
      // if (this.loginform.password.length !== 4) {
      //   ElMessage({
      //     message: '验证码长度为4位',
      //     offset: 100,
      //     type: "error",
      //     plain: true,
      //   });
      //   return
      // }
      // if (!reg_num.test(this.loginform.password)) {
      //   ElMessage({
      //     message: '验证码格式不正确',
      //     offset: 100,
      //     type: "error",
      //     plain: true,
      //   });
      //   return
      // }
      this.$store.dispatch("Login", this.loginform).then((res) => {
        let that = this;
        if (res.code === 200) {
          ElNotification({
            title: "Success",
            message: "登陆成功",
            type: "success",
            duration: 2000,
          });
          that.$router.push("/");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login_info {
  width: 100vw;
  min-width: 1005px;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .item {
    height: 100%;
    display: flex;
    flex-flow: column;
    border-radius: 8px;
    background: #ffffff;
    width: 500px;
    height: 400px;
    margin: auto;

    .item_title {
      margin-bottom: 15px;
      text-align: center;
      padding: 20px 0px;
      font-weight: 600;
      font-size: 32px;
      color: #53ca92;
    }

    .item_form {
      flex: 1;
      margin: auto;

      .form {
        margin: auto;

        .form_input {
          display: flex;
          margin-bottom: 20px;

          input:focus {
            outline: 1px solid #0df3b9;
            border: 2px solid #0df3b9;
          }

          input {
            padding: auto 5px;
            outline: 1px solid #ffffff;
            border: 2px solid rgba(0, 0, 0, 0.3);
            background: #eff4f9;
            height: 40px;
            border-radius: 8px;
            font-size: 20px;
          }

          .input_phone {
            padding: 0px 10px !important;
            width: 280px;
          }

          .input_code {
            padding: 0px 10px !important;
            width: 280px;
          }

          .input_button {
            margin-left: 10px;
            width: 170px;
          }

          .input_button_active {
            margin-left: 10px;
            width: 170px;
            border: 2px solid #ffffff !important;
            background: #ffffff00 !important;
            box-shadow: 0px 0px #b6b6b6 !important;
          }

          button {
            font-size: 16px;
            border: 2px solid #ffffff00;
            border-radius: 8px;
            background: #ffffff;
            box-shadow: 2px 2px #b6b6b6;
          }

          button:active {
            border: 2px solid #eff4f9;
            background: #eff4f9;
            box-shadow: 0px 0px #b6b6b6;
          }
        }

        .form_button {
          padding: 15px 0px;
          text-align: center;

          button {
            height: 40px;
            width: 280px;
            font-size: 16px;
            border: 2px solid #ffffff00;
            border-radius: 8px;
            background: #ffffff;
            box-shadow: 2px 2px #b6b6b6;
          }

          button:active {
            border: 2px solid #ffffff;
            background: #eff4f9;
            box-shadow: 0px 0px #b6b6b6;
          }
        }
      }
    }
  }
}
</style>
