import Cookie from "js-cookie";

const token_key = "admin_token";
export function getToken() {
  return Cookie.get(token_key)?Cookie.get(token_key):localStorage.getItem("token_key");
}

export function setToken(token) {
  localStorage.setItem("token_key", token)
  return Cookie.set(token_key, token);
}

export function removeToken() {
  localStorage.setItem("token_key", "")
  return Cookie.remove(token_key);
}
