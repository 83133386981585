<template>
    <div class="main_main_body">
        <div class="main_main_body_top">
            <div class="main_main_body_top_left">
                <slot name="top_left"></slot>
            </div>
            <div class="main_main_body_top_right">
                <slot name="top_right"></slot>
            </div>
        </div>
        <div class="main_main_body_main">
            <div class="main_main_body_main_card_top">
                <slot name="data_top">
                </slot>
            </div>
            <div v-if="show_loading" class="loading_center">
                <Loading></Loading>
            </div>
            <slot v-if="!show_loading" name="data_info">
            </slot>
            <slot v-if="!show_loading" name="page_info">
            </slot>
        </div>
        <div class="bottm_title_info">Powered&nbsp;by&nbsp;Chen</div>
    </div>
</template>

<script>
import Loading from "@/components/loading/loading.vue"
export default {
    props: ['show_loading'],
    components: {
        Loading
    },
}
</script>