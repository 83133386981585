import axios from "axios";
import { getToken } from "@/util/auth";
import { ElMessage } from "element-plus";
import router from "@/router";
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000,
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    const isToken = (config.headers || {}).isToken === 'true';
    if (getToken() && isToken) {
      config.headers["Authorization"] = getToken();
    }
    if (!isToken && !getToken()) {
      this.$router.push({ path: "/login" });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data?.code === 200) {
      return response.data;
    } else {
      ElMessage({
        message: response.data.message,
        offset: 100,
        type: "error",
        plain: true,
      });
      if (response.data.code === 103 || response.data.code === 101) {
        router.push({ path: "/login" });
      }
    }
  },
  (error) => {
    return error;
  }
);
export default service;
