<template>
  <div class="main_body">
    <div class="left_menu">
      <div class="menu_logo">
        <img src="@/assets/logo.png" width="48" height="48" />
        <div class="logo_title">一个AI批量剪辑工具</div>
      </div>
      <div class="menu">
        <router-link to="/" class="menu_item_active menu_item">
          <el-icon>
            <Stopwatch />
          </el-icon>
          <div class="menu_title">仪表盘</div>
        </router-link>
        <router-link to="/userList" class="menu_item">
          <el-icon>
            <UserFilled />
          </el-icon>
          <div class="menu_title">用户中心</div>
        </router-link>
        <router-link to="/indexOrder" class="menu_item">
          <el-icon>
            <Calendar />
          </el-icon>
          <div class="menu_title">订单中心</div>
        </router-link>
        <router-link to="/indexAffairs" class="menu_item">
          <el-icon>
            <Stamp />
          </el-icon>
          <div class="menu_title">单价管理</div>
        </router-link>
        <router-link to="/indexTask" class="menu_item">
          <el-icon>
            <Files />
          </el-icon>
          <div class="menu_title">任务分发</div>
        </router-link>
        <router-link to="/indexAnalyse" class="menu_item">
          <el-icon>
            <Monitor />
          </el-icon>
          <div class="menu_title">管理员中心</div>
        </router-link>
        <router-link to="/indexDy" class="menu_item">
          <el-icon>
            <Monitor />
          </el-icon>
          <div class="menu_title">抖音小程序</div>
        </router-link>
        <router-link to="/login" class="menu_item">
          <el-icon>
            <Coffee />
          </el-icon>
          <div class="menu_title">退出登录</div>
        </router-link>
      </div>
    </div>
    <div class="right_main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {
  Stopwatch,
  UserFilled,
  Calendar,
  Coffee,
  Monitor,
  Files,
  Stamp,
} from "@element-plus/icons-vue";
export default {
  components: {
    Stopwatch,
    UserFilled,
    Calendar,
    Coffee,
    Monitor,
    Files,
    Stamp,
  },
};
</script>

<style lang="less" scoped>
.main_body {
  min-width: 1005px;
  height: 100vh;
  display: flex;

  .left_menu {
    min-width: 180px;
    background: #ffffff;
    display: flex;
    flex-flow: column;
    flex: 1;
    overflow: hidden;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px -1px rgba(0, 0, 0, 0.1);
    z-index: 999;

    .menu_logo {
      margin: 15px 5px;
      text-align: center;

      .logo_title {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.4);
      }
    }

    .menu {
      flex: 1;
      display: flex;
      flex-flow: column;
      flex-flow: 1;
      height: 0;
      overflow: auto;

      a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.6);

        &.router-link-exact-active {
          color: #42b983;
          background: #f3f4f6;
        }

        &.router-link-exact-active::before {
          position: relative;
          left: -18px;
          width: 3px;
          height: 26px;
          content: "";
          background: #242e41;
          border-radius: 6px;
        }
      }

      .menu_item {
        display: flex;
        border-radius: 10px;
        align-items: center;
        height: 32px;
        margin: 5px 15px;
        padding: 5px 10px;

        .menu_title {
          margin-left: 5px;
        }
      }
    }
  }

  .right_main {
    min-width: 1005px;
    flex: 7;
  }
}
</style>
