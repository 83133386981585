<template>
    <div class="main_main_body_main_card_bottom">
        <div>共{{ list_num }}项数据</div>
        <div>
            <el-pagination :current-page="page_info.page" :page-size="page_info.pageNum"
                :page-sizes="[20, 50, 100, 400]" small :disabled="page_disabled"
                layout="total, sizes, prev, pager, next, jumper" :total="list_total" :page-count="page_count"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>
<script>
export default {
    props: ['list_num', 'page_info', 'page_disabled', 'list_total', 'page_count'],
    emits: ['handleSizeChange', 'handleCurrentChange'],
    methods:{
        handleSizeChange(param){
            this.$emit('handleSizeChange',param)
        },
        handleCurrentChange(param){
            this.$emit('handleCurrentChange',param)
        },
    }
}
</script>