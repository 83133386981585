import request from "@/util/request";
import axios from "axios";

export function getInfoByPage(param) {
    return request({
        url: "/dy/oInfo/page",
        headers: {
            isToken: true,
        },
        method: "post",
        data: param,
    });
}

export function update(param) {
    return request({
        url: "/dy/oInfo",
        headers: {
            isToken: true,
        },
        method: "put",
        data: param,
    });
}