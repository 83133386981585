
import request from "@/util/request";
export function getOrderPage(param) {
    return request({
        url: "/api/admin/order/page",
        headers: {
            isToken: true,
        },
        method: "post",
        data: param,
    });
}
export function uploadViews(param) {
    return request({
        url: "/api/admin/order/uploadView",
        headers: {
            isToken: true,
        },
        method: "post",
        data: param,
    });
}
export function getDoingInfo() {
    return request({
        url: "/api/admin/order/getDoingInfo",
        headers: {
            isToken: true,
        },
        method: "post",
    });
}

