
import request from "@/util/request";
export function getPage(param) {
    return request({
        url: "/api/admin/a-e-r/page",
        headers: {
            isToken: true,
        },
        method: "post",
        data: param,
    });
}
export function updateOrSave(param) {
    return request({
        url: "/api/admin/a-e-r/updateOrSave",
        headers: {
            isToken: true,
        },
        method: "post",
        data: param,
    });
}