<template>
  <div class="main_main_body">
    <div class="main_main_body_top">
      <div class="main_main_body_top_left">
        <el-icon>
          <Stopwatch />
        </el-icon>
        <div class="title_text">仪表盘</div>
      </div>
      <div class="main_main_body_top_right"></div>
    </div>
    <div class="main_main_body_main body_play">
      <div class="top_card_body">
        <div class="card_item">
          <div class="text_title_name">总用户</div>
          <div v-if="all_user_loading" class="text_title_num">{{ 'X,XXX' }}</div>
          <div v-else class="text_title_num">{{ all_user_num.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</div>
        </div>
        <div class="card_item">
          <div class="text_title_name">已处理事务</div>
          <div v-if="all_dO_loading" class="text_title_num">{{ 'X,XXX' }}</div>
          <div v-else class="text_title_num">{{ all_dO.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</div>
        </div>
        <div class="card_item">
          <div class="text_title_name">未处理事务</div>
          <div v-if="all_dO_loading" class="text_title_num">{{ 'X,XXX' }}</div>
          <div v-else class="text_title_num">{{ all_not_dO.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</div>
        </div>
        <div class="card_item">
          <div class="text_title_name">月收益</div>
          <div v-if="all_pay_loading" class="text_title_num">{{ 'X,XXX' }}</div>
          <div v-else class="text_title_num">{{ all_pay_num.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</div>
        </div>
      </div>
      <div class="middle_card_body">
        <div class="left">
          <div class="log_info">
            <div class="card_title">事务日志</div>
            <div v-if="log_loading" class="loading_center">
              <Loading></Loading>
            </div>
            <div v-else class="card_body_scoll">
              <div>123</div>
            </div>
          </div>
          <div class="tost_info">
            <div class="card_title">系统通知</div>
            <div v-if="notice_loading" class="loading_center">
              <Loading></Loading>
            </div>
            <div v-else class="card_body_scoll">
              <div>123</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="card_title">系统通知</div>
          <div v-if="system_notice_loading" class="loading_center">
            <Loading></Loading>
          </div>
          <div v-else class="card_body_scoll">
            <div>123</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottm_title_info">Powered&nbsp;by&nbsp;Chen</div>
  </div>
</template>
<script>
import { getUserRegirstInfo } from "@/api/user"
import { getAllPayNum } from "@/api/pay"
import {getDoingInfo} from "@/api/order"
import { Stopwatch } from "@element-plus/icons-vue";
import Loading from "@/components/loading/loading.vue";
export default {
  data() {
    return {
      all_pay_num: '0',
      all_pay_loading: false,
      all_user_num: '0',
      all_user_loading: false,
      all_dO_loading:false,
      all_dO:'0',
      all_not_dO:'0',
      log_loading: true,
      notice_loading: true,
      system_notice_loading: true,
    }
  },
  components: {
    Stopwatch,
    Loading,
  },
  mounted() {
    this.init_index()
  },
  methods: {
    init_index() {
      this.all_pay_loading = true
      this.all_user_loading = true
      this.all_dO_loading = true
      getUserRegirstInfo().then(res => {
        this.all_user_num = res?.data || '0'
        this.all_user_loading = false
      })
      getAllPayNum().then(res => {
        this.all_pay_num = res?.data || '0'
        this.all_pay_loading = false
      })
      getDoingInfo().then(res=>{
        this.all_dO = res?.data?.allDo || '0'
        this.all_not_dO = res?.data?.allNotDo || '0'
        this.all_dO_loading = false

      })
    }
  }
};
</script>
<style lang="less" scoped>
.body_play {
  display: flex;
  flex-flow: column;
}

.top_card_body {
  display: flex;
  align-items: center;
  text-align: center;

  .card_item:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  .card_item {
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 10px 10px;
    height: 80px;
    flex: 1;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px -1px rgba(0, 0, 0, 0.1);

    .text_title_name {
      margin-bottom: 5px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.4);
    }

    .text_title_num {
      font-weight: 600;
      color: #167fff;
    }
  }
}

.middle_card_body {
  margin-top: 10px;
  display: flex;
  align-items: center;
  text-align: center;

  .left {
    display: flex;
    border-radius: 10px;
    height: 100%;
    flex: 1;
    margin: 0 10px;

    .log_info {
      background: #ffffff;
      display: flex;
      flex-flow: column;
      border-radius: 10px;
      height: 500px;
      flex: 1;
      margin-right: 10px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }

    .tost_info {
      background: #ffffff;
      display: flex;
      flex-flow: column;
      border-radius: 10px;
      height: 500px;
      flex: 1;
      margin-left: 10px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }
  }

  .right {
    background: #ffffff;
    display: flex;
    flex-flow: column;
    border-radius: 10px;
    height: 500px;
    margin: 0 10px;
    flex: 1;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px -1px rgba(0, 0, 0, 0.1);
  }
}

.card_title {
  padding: 10px 10px;
  text-align: left;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.card_body_scoll {
  flex: 1;
  overflow: auto;
}
</style>
