<template>
    <div class="main_main_body">
        <div class="main_main_body_top">
            <div class="main_main_body_top_left">
                <el-icon>
                    <Files />
                </el-icon>
                <div class="title_text">任务发布</div>
            </div>
            <div class="main_main_body_top_right"></div>
        </div>
        <div class="main_main_body_main">
            <div class="main_main_body_main_card_top">
                <div class="main_main_body_main_card_top_left">
                    <el-input class="main_main_body_main_card_top_left_search_input" v-model="page_info.title"
                        placeholder="请输入订单名称" clearable />
                    <el-input class="main_main_body_main_card_top_left_search_input" v-model="page_info.userName"
                        placeholder="请输入创建人手机号" clearable />
                    <el-select class="main_main_body_main_card_top_left_search_chose" v-model="page_info.state"
                        placeholder="处理状态" clearable>
                        <el-option :key="0" label="未处理" :value="1" />
                        <el-option :key="1" label="已完成" :value="2" />
                    </el-select>
                    <el-select class="main_main_body_main_card_top_left_search_chose" v-model="page_info.template"
                        placeholder="模板类型" clearable>
                        <el-option :key="0" label="小红书模板" :value="0" />
                        <el-option :key="1" label="淘宝模板" :value="1" />
                        <el-option :key="2" label="抖音模板" :value="2" />
                        <el-option :key="3" label="B站模板" :value="3" />
                        <el-option :key="4" label="vlog模板" :value="4" />
                    </el-select>
                </div>
                <div class="main_main_body_main_card_top_right">
                    <el-button class="item" type="primary" @click="getOrderList">查询</el-button>
                </div>
            </div>
            <div v-if="task_list_loading" class="loading_center">
                <Loading>加载中···</Loading>
            </div>
            <div v-if="!task_list_loading" class="main_main_body_main_card_body">
                <div class="user_card_info_item" v-for="(item, index) in task_list">
                    <div class="user_card_info_item_left">
                        <div class="user_card_info_item_left_info">
                            <div class="user_card_info_item_left_info_top">{{ item.orderName || "无数" }}</div>
                            <div class="user_card_info_item_left_info_bottom">{{ item.createdBy }}</div>
                        </div>
                        <div class="user_card_info_item_left_template">
                            <el-tag type="primary">{{
                                item.templateId === '0' ? '小红书模板' : item.templateId === '1' ? '淘宝模板' : item.templateId
                                    === '2' ? '抖音模板' : item.templateId === '3' ? 'B站模板' : 'vlog模板'
                            }}</el-tag>
                        </div>
                        <div class="user_card_info_item_left_button">
                            <el-button type="primary" @click="download_file(item)">获取资料</el-button>
                            <el-button type="success" @click="opensubmit(item)">上传结果</el-button>
                        </div>
                    </div>
                    <div class="user_card_info_item_right">
                        <div class="user_card_info_item_right_balance">{{ item.payId }}</div>
                        <div class="user_card_info_item_right_tag_1">
                            <el-tag v-if="item.ovideo.state === '1'" type="primary">处理中</el-tag>
                            <el-tag v-else-if="item.ovideo.state === '2'" type="success">已完成</el-tag>
                            <el-tag v-else type="danger">未处理</el-tag>
                        </div>
                        <div class="user_card_info_item_right_create_time_2">
                            <div class="user_card_info_item_right_create_time_2_top">创建时间：{{ item.ovideo.createdTime }}
                            </div>
                            <div v-if="item.ovideo.state === '2'" class="user_card_info_item_right_create_time_2_top">
                                处理时间：{{ item.ovideo.updatedTime }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!task_list_loading" class="main_main_body_main_card_bottom">
                <div>共{{ task_list.length }}项数据</div>
                <div><el-pagination v-model:current-page="page_info.page" v-model:page-size="page_info.pageNum"
                        :page-sizes="[20, 50, 100, 400]" small :disabled="page_disabled" :background="background"
                        layout="total, sizes, prev, pager, next, jumper" :total="task_list_total"
                        :page-count="page_count" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" /></div>
            </div>
        </div>
        <div class="bottm_title_info">Powered&nbsp;by&nbsp;Chen</div>
    </div>
    <div v-if="progress_list.length != 0" class="msk">
        <div class="body">
            <div class="item_body_progress">
                <div class="item_progress" v-for="(item, index) in progress_list">
                    <div class="item_progress_text">{{ item.title }}</div>
                    <el-progress class="item_progress_progress" :percentage="item.progress"
                        :color="customColorMethod" />
                </div>
            </div>
        </div>
    </div>
    <div v-if="submit_file_show" class="msk" @click="close_msk">
        <div class="body_grid" @click="no_pop_up">
            <div class="item_top">
                <div class="title">
                    上传文件
                </div>
                <div class="info">
                    点击放空白处上传
                </div>
            </div>
            <div class="item_body_grid" @click="upload_file">
                <div class="item_body_grid_item" v-for="(item, index) in submit_file_from.data">
                    <div class="vide" @click="no_pop_up">
                        <video :src="item.url" controls></video>
                    </div>
                </div>
            </div>
            <div class="item_button">
                <el-button class="item" type="danger" @click="clear_file">清空</el-button>
                <el-button class="item" type="primary" @click="submit_file">提交</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { getOrderPage, uploadViews } from "@/api/order"
import { Files } from "@element-plus/icons-vue"
import Loading from "@/components/loading/loading.vue"
import axios from "axios"
import { ElMessage } from "element-plus";
export default {
    data() {
        return {
            submit_file_show: false,
            submit_file_from: {
                data: [],
                order_id: '',
                id: '',
                file_list: [],
            },
            page_info: {
                title: "",
                userName: "",
                state: "",
                template: "",
                pageNum: 20,
                page: 1,
            },
            page_disabled: false,
            page_count: 0,
            task_list_total: 0,
            task_list_loading: false,
            task_list: [],
            progress_list: [],

        }
    },
    computed: {

    },
    components: {
        Files,
        Loading
    },
    mounted() {
        this.getOrderList()
    },
    methods: {
        getOrderList() {
            this.page_disabled = true
            this.task_list_loading = true
            getOrderPage(this.page_info).then(res => {
                this.task_list = res?.data?.records || []
                this.task_list_total = res?.data?.total || 0
                this.page_count = res?.data?.pages || 0
                this.page_disabled = false
                this.task_list_loading = false
            })
        },
        handleCurrentChange(param) {
            this.page_info.page = param
            this.getOrderList()
        },
        handleSizeChange(param) {
            this.page_info.page = 1
            this.page_info.pageNum = param
            this.getOrderList()
        },
        download_file(param) {
            let that = this
            this.progress_list = []
            let path_list = param.ovideo.fileName.split("::>><<::")
            let count = 0
            for (let item of path_list) {
                this.progress_list.push({ title: item, progress: 0.0 })
            }
            for (let item in this.progress_list) {
                axios({
                    url: `/api/o-video/getVideoFile`,
                    responseType: "blob", // 流文件为blob类型
                    method: "get",
                    params: {
                        addres: `/${param.id}/${that.progress_list[item].title}`
                    },
                    onDownloadProgress(progress) {
                        that.progress_list[item].progress = (progress.progress * 100).toFixed(2)
                    }
                }).then((res) => {
                    let url = window.URL.createObjectURL(res.data)
                    count += 1
                    if (url) {
                        let link = document.createElement('a')
                        link.style.display = 'none'
                        link.href = url
                        link.setAttribute('download', that.progress_list[item].title)
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        window.URL.revokeObjectURL(url)
                    }
                    if (count >= that.progress_list.length) {
                        setTimeout(() => { that.progress_list = [] }, 1000)

                    }
                })
            }
        },
        clear_file() {
            this.submit_file_from.data = []
            this.submit_file_from.file_list = []
        },
        opensubmit(param) {
            let that = this
            that.submit_file_from = {
                data: [],
                order_id: param.id,
                id: param.ovideo.id,
                file_list: [],
            }
            console.log('param', param);
            if (param.ovideo.newDocument) {
                let path_list = param.ovideo.newDocument.split("::>><<::")
                for (let item in path_list) {
                    that.submit_file_from.data.push({ loading: true, url: '' })
                    axios({
                        url: `/api/o-video/getVideoFile`,
                        responseType: "blob", // 流文件为blob类型
                        method: "get",
                        params: {
                            addres: `/${param.id}/return/${path_list[item]}`
                        },
                        onDownloadProgress(progress) {
                            if (progress.progress === 1) {
                                that.submit_file_from.data[item].loading = true
                            }
                        }
                    }).then((res) => {
                        that.submit_file_from.data[item].url = window.URL.createObjectURL(res.data)
                        that.submit_file_from.file_list.push(res.data)
                    })
                }

            }
            that.submit_file_show = true
        },
        upload_file() {
            let that = this
            let inpEle = document.createElement("input")
            inpEle.type = "file"
            inpEle.style.display = "none"
            inpEle.accept = 10
            inpEle.multiple = true
            inpEle.addEventListener("change", () => {
                for (let item of inpEle.files) {
                    that.submit_file_from.data.push({ url: window.URL.createObjectURL(item) })
                    that.submit_file_from.file_list.push(item)
                }
            })
            inpEle.click()
        },
        no_pop_up(e) {
            e.stopPropagation()
        },
        close_msk() {
            this.submit_file_show = false
        },
        submit_file() {
            let that = this
            let formData = new FormData()
            for (let item of that.submit_file_from.file_list) {
                formData.append('file', item)
            }
            formData.append('order_id', that.submit_file_from.order_id)
            formData.append('id', that.submit_file_from.id)
            uploadViews(formData).then(res => {
                if (res?.code === 200) {
                    that.submit_file_show = false
                    ElMessage({
                        message: "上传成功",
                        offset: 100,
                        type: "success",
                        plain: true,
                    });
                    this.getOrderList()
                }
            })
        }
    }
};
</script>
<style lang="less" scoped></style>